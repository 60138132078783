// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history_button {
  display: inline-block;
  min-width: 100px;
  height: 30px;
  padding: 5px 13px;
  border: 1px solid #dedede;
  border-radius: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  background: #f3f3f3;
  color: #666666;
  cursor: pointer;
}
@media (max-width: 640px) {
  .history_button {
    min-width: 72px;
  }
}

.history_button:hover {
  background: #00AB2E;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/chat-wraper/components/messageDefault.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AADF;AAGE;EAhBF;IAiBI,eAAA;EAAF;AACF;;AAGA;EACE,mBAAA;EACA,cAAA;AAAF","sourcesContent":["@import '../../../assets/vars.scss';\n\n.history_button {\n  display: inline-block;\n  min-width: 100px;\n  height: 30px;\n  padding: 5px 13px;\n  border: 1px solid #dedede;\n  border-radius: 16px;\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 0.75rem;\n  background: #f3f3f3;\n  color: #666666;\n  cursor: pointer;\n\n  @media (max-width: $s) {\n    min-width: 72px;\n  }\n}\n\n.history_button:hover {\n  background: #00AB2E;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
