import React from 'react';
import { marked } from 'marked';
import asistentSVG from '../../../assets/img/assistant.svg';
import './text.scss';

/**
 * Text
 */
interface TextProps {
  children: string | React.ReactNode | React.ReactNode[];
  icon?: string;
  onClick?: () => void;
}

/**
 * @param props
 */
const Text: React.FC<TextProps> = ({ children, icon, onClick }: TextProps) => {
  const child = () => {
    if (typeof children === 'string') {
      const msg = marked(children);

      return (
        <div
          className="message"
          dangerouslySetInnerHTML={{
            __html: msg as string,
          }}
        />
      );
    } else {
      return <div className="message">{children}</div>;
    }
  };

  return (
    <div className="text">
      <div className="text__icon">
        <img src={icon ? icon : asistentSVG} alt="Robot message" />
      </div>
      <div
        className={'text__msg'}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <dd>Bender AI</dd>
        {child()}
      </div>
    </div>
  );
};

Text.displayName = 'Text';

export default Text;
