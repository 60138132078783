// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bender__delimiter {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem 0;
}
@media (max-width: 640px) {
  .bender__delimiter {
    width: 100%;
  }
}
.bender__delimiter::before {
  content: "";
  height: 0;
  flex: 1 1;
  border: 1px solid #dfdfdf;
}
.bender__delimiter .select {
  display: block;
}
@media (max-width: 640px) {
  .bender__delimiter {
    display: none;
  }
}

.select {
  display: none;
}
@media (max-width: 640px) {
  .select {
    display: block;
  }
  .select > div {
    border: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Select/select-assistent.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,iBAAA;AADF;AAGE;EAPF;IAQI,WAAA;EAAF;AACF;AAEE;EACE,WAAA;EACA,SAAA;EACA,SAAA;EACA,yBAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EAtBF;IAuBI,aAAA;EADF;AACF;;AAIA;EACE,aAAA;AADF;AAGE;EAHF;IAII,cAAA;EAAF;EAEE;IACE,YAAA;EAAJ;AACF","sourcesContent":["@import '../../../assets/vars.scss';\n\n.bender__delimiter {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 1.5rem;\n  padding: 1.5rem 0;\n\n  @media (max-width: $s) {\n    width: 100%;\n  }\n\n  &::before {\n    content: '';\n    height: 0;\n    flex: 1;\n    border: 1px solid #dfdfdf;\n  }\n\n  .select {\n    display: block;\n  }\n\n  @media (max-width: $s) {\n    display: none;\n  }\n}\n\n.select {\n  display: none;\n\n  @media (max-width: $s) {\n    display: block;\n\n    > div {\n      border: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
