// import { useState } from 'react';
import { useAppSelector } from '../../../hooks/storeHooks';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { Buttons, Text } from '../../ui';
import './messageDefault.scss';

export const MessageDefault = () => {
  const assistants = useAppSelector((state) => state.assistants.assistantList);
  const [, setQuery] = useQueryParams();
  const history = useAppSelector((state) => state.history.history);
  // const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const handlerAsistent = (value: string) => {
    setQuery({ assistant_id: value });
  };

  if (history.length > 1) {
    return <></>;
  }

  return (
    <>
      <Text>
        Hello, and welcome to the Vaccination Virtual Assistant! I&apos;m here to provide you with
        reliable and accessible information about vaccines, recommended vaccination schedules, and
        general guidance on registered vaccines in the USA, Germany, Brazil, and South Africa. Feel
        free to ask any questions or explore helpful resources to stay informed and protected.
        <Buttons>
          {assistants.slice(0).map((item) => (
            <button
              key={item.value}
              className="history_button"
              onClick={() => handlerAsistent(item.value)}
            >
              {item.label}
            </button>
          ))}
        </Buttons>
      </Text>
      {/* {showAdditionalInfo ? (
        <Text>Тут мог бы быть текст</Text>
      ) : (
        <Text>
          Hello! Welcome to the demo of a system that enables interaction with multiple assistants
          simultaneously. It can transfer users from one assistant to another while preserving the
          original query and dialogue history. This system facilitates user access to various
          knowledge areas and assistants performing different functions.
          <Buttons>
            <button className="history_button" onClick={() => handlerAsistent(assistants[0].value)}>
              {assistants[0].label}
            </button>
            <button className="history_button" onClick={() => setShowAdditionalInfo(true)}>
              Medical Cannabis
            </button>
          </Buttons>
        </Text>
      )} */}
    </>
  );
};
