import { format } from 'date-fns';

function formatMessage({ content, createdAt }) {
  const date = new Date(createdAt);
  const time = format(date, 'HH:mm:ss');
  const formattedDate = format(date, 'MM/dd/yyyy');
  const sender = content.type === 'Command' ? 'Me' : 'Bender AI';
  const message = content.props.children;

  return `${sender} ${time} ${formattedDate}\n\n${message}\n`;
}

export function convertMessagesToText(messages) {
  return messages.map(formatMessage).join('\n');
}
