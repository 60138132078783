import { Socket, io } from 'socket.io-client';
import { store } from '../store';
import {
  addMessage,
  setLoading,
  setThreadID,
  addMessageEvent,
  setRedirectedAssistantID,
} from '../store/message/messageFromAssistentSlice';

export let socket: Socket | undefined;

// Хранение ссылок на обработчики событий для очистки
const eventHandlers = new Map();

// Регистрация события с сохранением обработчика
const registerEventHandler = (eventName: string, handler: (...args: any[]) => void) => {
  if (socket) {
    socket.on(eventName, handler);
    eventHandlers.set(eventName, handler);
  }
};

// Удаление всех подписок
const removeAllEventHandlers = () => {
  if (socket) {
    eventHandlers.forEach((handler, eventName: string) => {
      socket!.off(eventName, handler);
    });
    eventHandlers.clear();
  }
};

export const useConnectSocket = async () => {
  socket = io(process.env.REACT_APP_API_ROUTE!, {
    transports: ['websocket'],
  });

  await new Promise<void>((resolve, reject) => {
    registerEventHandler('connect', () => {
      store.dispatch(setLoading(false));
      resolve();
    });

    registerEventHandler('connect_error', (error) => {
      console.error('Connection error:', error);
      reject(error);
    });
  });

  socket?.emit('clientMessage', {
    socket_id: socket.id,
    message: 'Client is connection!',
  });

  // Регистрация событий
  registerEventHandler('message', (message) => {
    store.dispatch(addMessage(message));
  });

  registerEventHandler('messageEvent', (message) => {
    store.dispatch(addMessageEvent(message));
  });

  registerEventHandler('customDisconnect', (message) => {
    console.log(message);
  });

  registerEventHandler('isLoading', (state) => {
    store.dispatch(setLoading(state));
  });

  registerEventHandler('redirectedAssistantId', ({ newAssistantId, newThreadId }) => {
    store.dispatch(setRedirectedAssistantID(newAssistantId));
    store.dispatch(setThreadID(newThreadId));
  });

  registerEventHandler('disconnect', () => {
    store.dispatch(setLoading(false));
  });
};

export const cleanupSocket = () => {
  if (socket) {
    removeAllEventHandlers();
    socket.disconnect();
    store.dispatch(setLoading(false));
  }
};

export const sendMessageToAssistent = (
  assistant_id: string,
  thread_id: string,
  message: string
) => {
  if (message && assistant_id && thread_id && socket) {
    store.dispatch(setLoading(true));
    socket.emit('message', { client_id: socket.id, assistant_id, thread_id, message });
  }
};

interface StartChatResponse {
  threadId?: string;
  error?: string;
}

export const startChat = (assistant_id: string) => {
  if (assistant_id && socket) {
    store.dispatch(setLoading(true));

    socket.emit(
      'startChat',
      { assistant_id, client_id: socket.id },
      (response: StartChatResponse) => {
        if (response && response.threadId) {
          store.dispatch(setThreadID(response.threadId));
        } else {
          console.error('Error starting chat:', response.error);
          store.dispatch(setLoading(false));
        }
      }
    );
  }
};

export const stopChat = () => {
  if (socket) {
    socket.emit('stopChat', { client_id: socket.id }, (response: { error?: string }) => {
      if (response && response.error) {
        console.error('Error stopping chat:', response.error);
      } else {
        console.log('Chat stopped successfully');
      }
    });
  }
};

export const resetChat = (assistant_id: string) => {
  if (assistant_id && socket) {
    socket.emit(
      'resetChat',
      { assistant_id, client_id: socket.id },
      (response: StartChatResponse) => {
        if (response && response.threadId) {
          store.dispatch(setThreadID(response.threadId));
        } else {
          console.error('Error resetting chat:', response.error);
          store.dispatch(setLoading(false));
        }
      }
    );
  }
};

export const deleteChat = () => {
  if (socket) {
    socket.emit('deleteChat', { client_id: socket.id }, (response: { error?: string }) => {
      if (response && response.error) {
        console.error('Error delete chat:', response.error);
      } else {
        console.log('Chat delete successfully');
      }
    });
  }
};

export const redirectChat = (old_assistant_id: string, new_assistant_id: string) => {
  if (socket) {
    socket.emit('redirectChat', { old_assistant_id, new_assistant_id, client_id: socket.id });
  }
};
