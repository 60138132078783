import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { SelectAssistent } from '../ui';
import { ReactComponent as LogoSvg } from '../../assets/img/logo_updated.svg';
import { ReactComponent as AssistantSVG } from '../../assets/img/assistant.svg';

const Header: React.FC<{ isIframe: boolean }> = ({ isIframe }) => {
  const { t } = useTranslation('common');
  const [menuOpen, setMenuOpen] = useState(false);
  const navRef = useRef<HTMLDivElement | null>(null);
  const hamburgerRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event: any) => {
    if (
      navRef.current &&
      !navRef.current.contains(event.target as Node) &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <header>
      <div
        className={cn('bender__header', {
          iframe: isIframe,
        })}
      >
        <div className="bender__header_block">
          <div className="logo">
            <LogoSvg className="logo-desktop" />
            <AssistantSVG className="logo-mobile" />
          </div>

          {/* <LogoSvg onClick={() => history.back()}/>*/}

          <SelectAssistent />

          <div
            ref={hamburgerRef}
            className={`hamburger ${menuOpen ? 'open' : ''}`}
            onClick={toggleMenu}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          <nav ref={navRef} className={menuOpen ? 'open' : ''}>
            <Link
              to="https://benderpharma.eu/products?utm_source=assistant"
              target={'_blank'}
              rel={'noopener noreferrer'}
              onClick={handleLinkClick}
            >
              {t('Our AI Products')}
            </Link>
            <Link
              to="https://www.linkedin.com/in/pavelbender/"
              target={'_blank'}
              rel={'noopener noreferrer'}
              onClick={handleLinkClick}
            >
              {t('LinkedIn')}
            </Link>
            <Link
              to="https://www.linkedin.com/in/pavelbender/"
              target={'_blank'}
              rel={'noopener noreferrer'}
              onClick={handleLinkClick}
            >
              {t('Contact')}
            </Link>
          </nav>
        </div>
        <div className="bender__delimiter">
          {/*
          <SelectAssistent />
          */}
        </div>
      </div>
    </header>
  );
};

export default Header;
